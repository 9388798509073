import { update } from "ramda"
import autobahn from "autobahn"
import { WampSessionNotAvailableError } from "js/includes/common/types/errors"
import { isNilOrEmpty, ninjaReportError } from "js/includes/common/utils"

export default class WampConnection {
  constructor({
    sessionConnectUrl,
    sessionConnectAuthUser,
    applicationEnvironment,
    userUid,
    sessionDivisionUid,
    deleteAuthUser,
  }) {
    this.lastCommandTimestamp = 0
    this.sessionConnectUrl = sessionConnectUrl
    this.sessionConnectAuthUser = sessionConnectAuthUser
    this.applicationEnvironment = applicationEnvironment
    this.userUid = userUid
    this.sessionDivisionUid = sessionDivisionUid
    this.deleteAuthUser = deleteAuthUser
    this.initializeConnection()
  }

  // establish connection using autobahnjs
  async initializeConnection() {
    this.globalSubscriptions = []
    this.initialSessionOpen = new Promise((resolve, reject) => {
      // connect to wamp
      this.connection = new autobahn.Connection({
        url: this.sessionConnectUrl,
        realm: "ninja",
        authmethods: ["wampcra", "anonymous"],
        authid: this._getUser(),
        max_retries: -1,
        onchallenge: (session, method, extra) => {
          if (method === "wampcra") {
            return autobahn.auth_cra.sign(this.userUid, extra.challenge)
          }
        },
      })

      this.connection.onopen = (session, details) => {
        if (!this.session) {
          this.session = session
        }

        if (!!this.globalSubscriptions.length) {
          this.globalSubscriptions.forEach(sub => session.subscribe(sub.topic, sub.handler))
        }
        resolve()
      }

      this.connection.onclose = (reason, details) => {
        this.disconnectDetails = `${reason} ${JSON.stringify(details)}`
        this.session = null

        resolve()
      }

      this.connection.open()
    })
  }

  async subscribeToChannel(name, action, nodeId) {
    await this.initialSessionOpen

    if (!this.session) throw new WampSessionNotAvailableError(this.disconnectDetails)

    const channel = nodeId ? `${this._getAgentId(nodeId)}.${name}` : this._getDivisionChannelName(name)
    const sub = await this.session.subscribe(channel, action)
    const duplicateIndex = this.globalSubscriptions.findIndex(item => item.topic === sub.topic)
    if (duplicateIndex === -1) {
      this.globalSubscriptions.push(sub)
    } else {
      this.globalSubscriptions = update(duplicateIndex, sub, this.globalSubscriptions)
    }
  }

  async unSubscribeToChannel(sub) {
    if (isNilOrEmpty(sub)) {
      return
    }

    //If successful, the boolean returned indicates whether the underlying WAMP subscription was actually ended (true)
    //or not, since there still are application handlers in place due to multiple client-side subscriptions
    //for the same WAMP subscription to the broker.
    try {
      await this.initialSessionOpen

      const success = this.session && sub.active && (await this.session.unsubscribe(sub))

      if (success || !sub.active) {
        const subIdx = this.globalSubscriptions.findIndex(item => item.topic === sub.topic)

        if (subIdx > -1) {
          this.globalSubscriptions.splice(subIdx, 1)
        }
      }

      return success
    } catch (error) {
      ninjaReportError(error)
    }
  }

  publish = event => {
    // TODO: publish events from web
  }

  async call(id, command, args, kwargs, options) {
    await this.initialSessionOpen

    if (!this.session) throw new WampSessionNotAvailableError(this.disconnectDetails)
    return this.session.call(`${this._getAgentId(id)}.${command}`, args, kwargs, options)
  }

  subscribeToNodeEvents = (nodeId, event, handler) => {
    // TODO: subscribe to events on a give n node
  }

  _getDomain = () => `web.${this.applicationEnvironment}.ninjarmm.com`

  _getUser() {
    this.deleteAuthUser && this.deleteAuthUser()

    return `${this.sessionConnectAuthUser}@${this._getDomain()}`
  }

  _getDivisionNamespace() {
    return `${this.applicationEnvironment}.${this.sessionDivisionUid}`
  }

  _getAgentId(id) {
    return `${this._getDivisionNamespace()}.agent.${id}`
  }

  _getDivisionChannelName(name) {
    return `${this._getDivisionNamespace()}.web.channel.${name}`
  }
}
