import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { ShowOptionsIcon } from "@ninjaone/icons"
import Avatar from "../Avatar"
import TitleGroup from "../TitleGroup"
import Dropdown from "../Dropdown"

const DropdownContainer = styled.div`
  [data-ninja-dropdown-trigger] {
    width: 100%;
    color: ${({ theme }) => theme.color.text};
    margin-bottom: ${sizer(4)};
  }
`

const StyledEditorContext = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${({ hasDropdown }) => (hasDropdown ? "0" : sizer(4))};

  a {
    color: inherit;
  }
`

export const StyledBorderedContainer = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${({ theme }) => theme.color.border};
  border-radius: ${sizer(1)};
`

export const StyledEditorContextTarget = styled.div`
  grid-row: 1 / 2;
  display: grid;
  align-items: center;
  text-align: left;
  grid-template-columns: ${({ hasAvatar }) => (hasAvatar ? sizer(7, "minmax(0, 1fr)", 4) : sizer("minmax(0, 1fr)", 4))};
  grid-column-gap: ${sizer(3)};
  padding: ${sizer(5)};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "")};
  user-select: none;
  border-radius: ${sizer(1)};
  background-color: ${({ theme }) => theme.color.background};
`

export default function EditorContext({ avatar, titleGroup, contextDropdownOptions, LinkComponent }) {
  const Context = () => (
    <StyledEditorContext hasDropdown={!!contextDropdownOptions}>
      <StyledBorderedContainer>
        <StyledEditorContextTarget hasAvatar={!!avatar}>
          {avatar && <Avatar data={avatar} />}
          <TitleGroup {...{ size: "sm", ...titleGroup }} />
          {LinkComponent ? <LinkComponent /> : contextDropdownOptions ? <ShowOptionsIcon /> : null}
        </StyledEditorContextTarget>
      </StyledBorderedContainer>
    </StyledEditorContext>
  )

  if (contextDropdownOptions) {
    const options = contextDropdownOptions.filter(({ hide }) => !hide)

    return (
      <DropdownContainer>
        <Dropdown matchWidth options={options}>
          <Context />
        </Dropdown>
      </DropdownContainer>
    )
  } else {
    return <Context />
  }
}
