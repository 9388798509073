import { useCallback, useEffect } from "react"
import { useMountedState } from "@ninjaone/utils"
import { getTicketById } from "js/includes/common/client"
import { parseTicketDataFromServer } from "js/includes/ticketing/editor/shared/utils"
import { isTicketNotFoundError, isTicketForbiddenError } from "js/includes/ticketing/shared/utils"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

const initialTicketStatus = {
  ticket: null,
  fetchingTicket: true,
  fetchError: false,
}

export function useTicket(ticketId) {
  const [ticketStatus, setTicketStatus] = useMountedState(initialTicketStatus)

  const fetchTicket = useCallback(async () => {
    setTicketStatus(initialTicketStatus)

    try {
      const ticketResponse = await getTicketById(ticketId)
      const upToDateTicket = parseTicketDataFromServer(ticketResponse)

      setTicketStatus(prevState => ({ ...prevState, ticket: upToDateTicket }))
    } catch (error) {
      if (isTicketNotFoundError(error)) {
        reportErrorAndShowMessage(error, localizationKey("Error ticket not found"))
      } else if (isTicketForbiddenError(error)) {
        reportErrorAndShowMessage(error, localizationKey("Error ticket forbidden"))
      } else {
        reportErrorAndShowMessage(error, localizationKey("Error fetching ticket"))
      }
      setTicketStatus(prevState => ({ ...prevState, fetchError: true }))
    } finally {
      setTicketStatus(prevState => ({ ...prevState, fetchingTicket: false }))
    }
  }, [setTicketStatus, ticketId])

  useEffect(fetchTicket, [fetchTicket])

  return {
    ...ticketStatus,
  }
}
