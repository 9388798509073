import PropTypes from "prop-types"

import { localized } from "js/includes/common/utils"
import { EditorHeader } from "js/includes/components/Editors"
import { Content, Editor, Title } from "js/includes/components/Styled/Editor"
import BackupManager from "js/includes/systemDashboard/backups/Manage/BackupManager"

const BackupManagerModal = ({ node, filterAction, filterColumn, unmount: close, isFileFolderBackupAllowed }) => {
  const deviceName = node.displayName || node.friendlyName || node.name || node.systemName || ""

  return (
    <Editor>
      <EditorHeader {...{ close }} />

      <Title>
        {deviceName} - {localized("Manage Backups")}
      </Title>

      <Content>
        <BackupManager
          {...{
            node,
            filterAction,
            filterColumn,
            isFileFolderBackupAllowed,
          }}
        />
      </Content>
    </Editor>
  )
}

BackupManagerModal.propTypes = {
  node: PropTypes.object.isRequired,
  unmount: PropTypes.func,
  filterColumn: PropTypes.func,
  filterAction: PropTypes.func,
}

BackupManagerModal.defaultProps = {
  filterColumn: () => true,
  filterAction: () => true,
}

export default BackupManagerModal
