import { useEffect } from "react"
import { Modal } from "@ninjaone/components"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { applyMultipleValidations, localizationKey, localized, validations } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import Loading from "js/includes/components/Loading"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { useTicket } from "js/includes/ticketing/shared/hooks"

export const ReAssignIncidentModal = ({ unmount, onSave, ticketId }) => {
  const { ticket, fetchingTicket, fetchError } = useTicket(ticketId)
  const [savingTicket, setSavingTicket] = useMountedState(false)

  const {
    values: { parentTicket },
    onChange,
    validateForm,
    validation: { message },
    updateValidate,
  } = useForm({
    fields: { parentTicket: null },
    validate: {
      parentTicket: validations.required,
    },
  })

  useEffect(() => {
    const parentTicketId = ticket?.parentTicket?.id
    if (parentTicketId) {
      updateValidate({
        parentTicket: applyMultipleValidations([
          validations.required,
          value => {
            const success = value?.id !== parentTicketId
            return { success, message: success ? "" : localized("Cannot assign ticket to the same problem") }
          },
        ]),
      })
    }
  }, [ticket?.parentTicket?.id, updateValidate])

  async function handleSave() {
    if (!validateForm()) return
    setSavingTicket(true)
    await onSave({ parentTicket, ticket, unmount })

    setSavingTicket(false)
  }

  return (
    <Modal
      unmount={unmount}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Save"),
          onClick: handleSave,
          disabled: fetchError || parentTicket === null || savingTicket,
        },
      ]}
      titleGroup={{
        titleToken: localizationKey("Reassign Incident"),
        descriptionToken: localizationKey("Select the problem this incident belongs to"),
      }}
      cancelable
    >
      {fetchingTicket ? (
        <Flex minHeight="38px" justifyContent="center">
          <Loading />
        </Flex>
      ) : (
        <SearchableDropDown
          width="100%"
          pageSize={50}
          endpoint="/ticketing/ticket/problem"
          valueSelectorKey="id"
          searchableKey="name"
          openOnFocus
          keepDropdownInView
          value={parentTicket}
          searchParams={({ query }) => ({ ...(query && { searchCriteria: query }) })}
          rowRenderer={({ id, name }) => `#${id} ${name}`}
          onSelect={selected => {
            onChange({ parentTicket: selected })
          }}
          errorMessage={message.parentTicket}
          validationState={message.parentTicket && "error"}
          useSelectStyling
        />
      )}
    </Modal>
  )
}
