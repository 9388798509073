import { ligatures, unicode, svgPathData } from "./faThreeRackServer"

const prefix = "fac"
const iconName = "NinjaBackup"
const width = 512
const height = 512

const faNinjaBackup = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export default faNinjaBackup
