import styled from "@emotion/styled"
import { compose, ifElse, always } from "ramda"
import { formatNumber, parseNumber, isValidNumber } from "libphonenumber-js"
import { Body, Link } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { getContactUsUrl, localized } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import PrivacyLinks from "./PrivacyLinks"
import FooterCopyright from "./FooterCopyright"

function formattedPhone(phone) {
  return compose(
    ifElse(isValidNumber, parsedNumber => formatNumber(parsedNumber, "International"), always(phone)),
    parseNumber,
  )(phone)
}

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  padding: 0 ${tokens.spacing[6]};
  border-top: 1px solid ${({ theme }) => theme.colorBorderWeakest};

  .tech-email {
    word-wrap: break-word;
  }
`

export default function Footer({ isBranded, websiteBranding, showPrivacyLinks }) {
  return (
    <StyledFooter id="footer">
      {isBranded ? (
        <Flex gap={tokens.spacing[3]} alignItems="center">
          {websiteBranding.brandingNode.general.phoneSettings.enabled && (
            <div>
              <Body as="span" fontWeight={tokens.typography.fontWeight.semiBold} color="colorTextWeakest">
                {localized("Phone")}&nbsp;
              </Body>
              <Link href={`tel:${websiteBranding.brandingNode.general.phoneSettings.number}`}>
                {formattedPhone(websiteBranding.brandingNode.general.phoneSettings.number)}
              </Link>
            </div>
          )}
          {websiteBranding.brandingNode.general.emailSettings.enabled && (
            <div>
              <Body as="span" fontWeight={tokens.typography.fontWeight.semiBold} color="colorTextWeakest">
                {localized("Technical Support")}&nbsp;
              </Body>
              <Link className="tech-email" href={`mailto:${websiteBranding.brandingNode.general.emailSettings.email}`}>
                {websiteBranding.brandingNode.general.emailSettings.email}
              </Link>
            </div>
          )}
        </Flex>
      ) : (
        <>
          {showPrivacyLinks ? (
            <>
              <Flex justifyContent="start" alignItems="center">
                <PrivacyLinks />
              </Flex>
              <FooterCopyright />
            </>
          ) : (
            <>
              <Flex justifyContent="start" alignItems="center" gap={2}>
                <Link
                  href={getContactUsUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  fontWeight={tokens.typography.fontWeight.medium}
                >
                  {localized("Contact us ")}
                </Link>
                <PrivacyLinks />
              </Flex>
              <FooterCopyright />
            </>
          )}
        </>
      )}
    </StyledFooter>
  )
}
