import { useState } from "react"
import styled from "@emotion/styled"
import { CcapSvg, ExternalLinkIconLight, OpenArrowIcon } from "@ninjaone/icons"
import { Button, Dropdown, StyledButtonLink, Text } from "@ninjaone/components"
import { spacing, typography } from "@ninjaone/tokens"
import { Flex } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"

const NINJA_ONE_URL = "https://www.ninjaone.com"

const StyledSpan = styled.span`
  display: flex;
  margin-bottom: -2px;
`

const StyledButton = styled(Button)`
  border: none;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
`

const StyledIcon = styled(OpenArrowIcon)`
  &&&:hover: {
    text-decoration: underline;
  }
`

const optionsData = [
  {
    labelToken: localizationKey("Privacy policy"),
    endpoint: "/privacy-policy/",
    icon: <ExternalLinkIconLight size="xs" />,
  },
  {
    labelToken: localizationKey("CA privacy info"),
    endpoint: "/privacy-policy/#ca-residents",
    icon: <ExternalLinkIconLight size="xs" />,
  },
  {
    labelToken: localizationKey("Your privacy choices"),
    endpoint: "/your-privacy-choices/",
    icon: (
      <>
        <ExternalLinkIconLight size="xs" />
        <StyledSpan>
          <CcapSvg />
        </StyledSpan>
      </>
    ),
  },
]

const DropdownOption = ({ token }) => {
  return (
    <StyledButtonLink>
      <Text token={token} type="bodyXs" />
    </StyledButtonLink>
  )
}

const LabelComponent = ({ labelToken, icon }) => (
  <Flex height={spacing[1]} alignItems="center" gap={1}>
    <DropdownOption token={labelToken} />
    {icon}
  </Flex>
)

const options = optionsData.map(({ labelToken, endpoint, icon }) => ({
  LabelComponent: () => <LabelComponent labelToken={labelToken} icon={icon} />,
  action: () => {
    window.open(`${NINJA_ONE_URL}${endpoint}`, "_blank", "noopener noreferrer")
  },
  id: localized(labelToken),
}))

const PrivacyLinks = () => {
  const [iconDirection, setIconDirection] = useState(false)

  const handleDropdownStateChange = isOpen => {
    setIconDirection(isOpen)
  }

  return (
    <Dropdown {...{ options, onOpenChange: handleDropdownStateChange }}>
      <StyledButton renderAsDiv variant="secondary">
        <Text token={localizationKey("Privacy")} type="body" fontWeight={typography.fontWeight.medium} />
        <StyledIcon direction={iconDirection ? "up" : "down"} size="xs" />
      </StyledButton>
    </Dropdown>
  )
}

export default PrivacyLinks
