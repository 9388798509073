const faRdp = {
  prefix: "fac",
  iconName: "rdp",
  icon: [
    189,
    153,
    [],
    "0027",
    `M123.547,46c-19.905,4 -35.334,18.476 -41.524,38.857c-4.095,13.429 -1.524,31.334 6.095,43.143c6,9.429 16.667,18.19 26.952,22.095c6.857,2.667 22.953,3.715 31.238,1.905c14,-2.857 28.762,-14 35.429,-26.476c6.952,-13.143 8.381,-29.619 3.809,-43.333c-3.333,-9.81 -7.142,-15.715 -14.571,-22.572c-12.476,-11.619 -30.857,-16.857 -47.428,-13.619Zm24.761,9.048c12.477,4.19 22,12.571 28,24.762c3.143,6.476 4.323,14.917 4.299,19.267c-0.023,4.127 -2.013,17.59 -9.061,27.494c-4.857,6.858 -13.904,13.715 -21.904,16.477c-25.143,8.857 -52.667,-5.524 -60.095,-31.429c-2.286,-7.905 -1.62,-21.048 1.428,-28.857c8.857,-22.762 34.762,-35.238 57.333,-27.714Zm-147.142,-53.619c-0.857,0.952 -1.143,14.476 -1.143,48.666c0,44.191 0.095,47.429 1.714,48.667c1.429,1.048 8.572,1.238 32.572,1.048l30.857,-0.286l-0.286,9.333c-0.286,9.048 -0.476,9.524 -3.143,11.524c-2.286,1.714 -4.667,2.19 -14.19,2.571c-9.429,0.477 -11.905,0.953 -14.191,2.667c-3.143,2.286 -4.095,5.714 -2.286,7.905c2.096,2.571 16.286,1.905 24.762,-1.048c5.81,-2 9.143,-2.476 16.857,-2.476c7.239,0 9.429,-0.286 9.048,-1.238c-6.667,-15.905 -8.286,-23.333 -7.238,-34.381l0.667,-7.429l-31.524,0l-31.524,0l0,-37.428l0,-37.524l122,0l0,27.81l5.905,0.381l6,0.285l0.285,-18.381c0.191,-13.904 -0.095,-18.857 -1.047,-20.19c-1.238,-1.81 -4.191,-1.905 -72.095,-1.905c-59.143,0 -71.048,0.191 -72,1.429Zm122.666,95.809c5.619,5.619 10.286,10.667 10.286,11.238c0,0.572 -4.667,5.715 -10.286,11.334l-10.285,10.19l-3.715,-3.809l-3.714,-3.715l6.476,-6.476c3.524,-3.524 6.476,-6.952 6.476,-7.524c0,-0.571 -2.952,-3.905 -6.476,-7.428l-6.476,-6.477l7.429,-7.619l10.285,10.286Zm20.191,-17.714l-10.476,10.476l21.523,21.524l3.715,-3.81l3.714,-3.714l-6.953,-6.952l-6.952,-7.048l6.952,-6.952l6.953,-7.048l-3.429,-3.524c-1.904,-1.904 -3.619,-3.524 -4,-3.524c-0.285,0 -5.238,4.762 -11.047,10.572Z`,
  ],
}

export default faRdp
