function isUsingUploads(uploads, htmlBody) {
  const html = htmlBody || ""
  return (uploads || []).some(upload => {
    const isInline = upload.metadata.inline
    if (!isInline) {
      return true
    }
    return html.includes(upload.metadata.contentId)
  })
}

export function isEditorNotEmpty({ htmlBody, body, uploads }) {
  return !!body?.trim() || isUsingUploads(uploads, htmlBody)
}
