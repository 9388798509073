export function wait(interval) {
  return new Promise(resolve => setTimeout(resolve, interval))
}

export async function retryPromise(fn, retriesLeft = 2, interval = 100, passWhenNoRetriesLeft) {
  try {
    return await fn(retriesLeft)
  } catch (error) {
    await wait(interval)
    if (retriesLeft === 0) {
      if (passWhenNoRetriesLeft) {
        return
      }
      throw error
    }
    return await retryPromise(fn, --retriesLeft, interval, passWhenNoRetriesLeft)
  }
}

export function promiseQueue() {
  const queue = []
  let workingOnPromise = false

  function dequeue() {
    return queue.shift()
  }

  function runNextPromise() {
    if (!queue.length) return
    const nextItem = dequeue()
    runPromise(nextItem)
  }

  async function runPromise(item) {
    if (workingOnPromise || !item) {
      return
    }

    try {
      workingOnPromise = true
      const value = await item.promise()
      item.resolve(value)
    } catch (error) {
      item.reject(error)
    } finally {
      workingOnPromise = false
      runNextPromise()
    }
  }

  function enqueue(promise) {
    return new Promise((resolve, reject) => {
      queue.push({
        promise,
        resolve,
        reject,
      })
      !workingOnPromise && runNextPromise()
    })
  }

  return {
    enqueue,
  }
}
