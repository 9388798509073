import { Body } from "@ninjaone/components"
import { Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

const FooterCopyright = () => {
  return (
    <Flex justifyContent="end">
      <Body color="colorTextWeakest">{localized("NinjaOne LLC © 2014-2024")}</Body>
    </Flex>
  )
}

export default FooterCopyright
