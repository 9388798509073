import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { sizer } from "@ninjaone/utils"
import { localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

import Button, { VARIANTS } from "./Button"

export const StyledButtonGroup = styled.div`
  ${({ fullWidth }) => fullWidth && "flex: 1;"}
  display: grid;
  justify-content: end;
  align-content: center;
  grid-column-gap: ${sizer(3)};
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-auto-rows: min-content;
`

export default function ButtonGroup({ buttons, className, fullWidth = true }) {
  return (
    <StyledButtonGroup {...{ className, fullWidth }}>
      {buttons.map(
        (
          {
            labelToken,
            labelText,
            type,
            submit,
            onClick,
            hide,
            linkTo,
            disabled,
            variant,
            onMouseDown,
            Icon,
            iconPlacement,
            form,
            tooltip,
            tooltipPosition,
          },
          index,
        ) =>
          !hide && (
            <Button
              {...{
                key: labelToken ?? labelText ?? index,
                labelToken,
                type,
                submit,
                variant,
                onClick,
                linkTo,
                disabled,
                onMouseDown,
                Icon,
                iconPlacement,
                form,
                tooltip,
                tooltipPosition,
                labelText,
              }}
            />
          ),
      )}
    </StyledButtonGroup>
  )
}

export const DefaultButtonGroup = ({
  unmount,
  actionToken,
  onConfirm,
  disabled,
  submit,
  closeToken = localizationKey("Cancel"),
  fullWidth = true,
}) => (
  <StyledButtonGroup {...{ fullWidth }}>
    <Button variant={VARIANTS.SECONDARY} labelToken={closeToken} onClick={unmount} />
    <Button
      variant={VARIANTS.PRIMARY}
      labelToken={actionToken}
      onClick={onConfirm}
      disabled={disabled}
      submit={submit}
    />
  </StyledButtonGroup>
)

export const DangerButtonGroup = ({
  dangerActionToken,
  onConfirm,
  onCancel,
  disabled,
  submit,
  closeToken = localizationKey("Cancel"),
  fullWidth = true,
}) => (
  <StyledButtonGroup {...{ fullWidth }}>
    <Button variant={VARIANTS.ACCESSORY} labelToken={closeToken} onClick={onCancel} />
    <Button
      variant={VARIANTS.DANGER}
      labelToken={dangerActionToken}
      onClick={onConfirm}
      disabled={disabled}
      submit={submit}
    />
  </StyledButtonGroup>
)

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(Button),
  className: PropTypes.string,
}
