import { reduce } from "ramda"
import { Editor, Transforms, Element as SlateElement } from "slate"

export const getEmptyBlock = () => ({ type: "paragraph", children: [{ text: "" }] })

export const insertDefaultBlock = editor => {
  Transforms.insertNodes(editor, getEmptyBlock(), { select: true, mode: "highest" })
}

export const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    }),
  )

  return !!match
}

export const isElementSelected = (editor, element) => {
  const { selection } = editor
  return selection !== null && selection.anchor !== null && element === editor.children[selection.anchor.path[0]]
}

export const readLocalFileAsUrl = (file, isImage = true) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file)

    reader.onload = e => {
      if (isImage) {
        const image = new Image()

        image.onload = () => {
          resolve([e.target.result, image])
        }

        image.onerror = reject

        image.src = e.target.result
      } else {
        resolve([e.target.result])
      }
    }

    reader.onerror = reject
  })
}

export const getAttachments = (value, getAttachment = attachment => attachment) =>
  reduce(
    (acc, { type, attachment }) => {
      if (type === "image") {
        acc.push(getAttachment(attachment))
      }
      return acc
    },
    [],
    value,
  )

export const LIST_TYPES = ["numbered-list", "bulleted-list"]
export const BLOCK_TYPES = ["block-quote", "block-code", "heading-one", "heading-two"]
export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"]

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })

  let newProperties: Partial<SlateElement>

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    }
  }

  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}
