import React, { memo, useEffect, useState } from "react"
import { Spinner } from "@ninjaone/components"
import { useMounted, useMountedState } from "js/includes/common/hooks"
import { StyledImage } from "js/includes/components/Styled"
import { Box, StyledFontAwesomeIcon, StyledSpan } from "js/includes/components/Styled"
import { faFileImage } from "@fortawesome/pro-solid-svg-icons"
import { localized, ninjaReportError } from "js/includes/common/utils"
import { getSignedUrlFromAttachmentId, getSignedUrlFromContentId } from "js/includes/common/client"

export const AWSResourceImage = memo(({ contentId, attachmentId, width, tempUri, uploadStatus, ...otherProps }) => {
  const [signedUrl, setSignedUrl] = useState(null)
  const [isResourceBroken, setIsResourceBroken] = useState(false)
  const [isLoading, setIsLoading] = useMountedState(false)
  const mounted = useMounted()

  useEffect(() => {
    if (!attachmentId && !contentId) {
      return
    }
    const getUrl = async () => {
      try {
        setIsLoading(true)
        const { signedUrl } = await (contentId
          ? getSignedUrlFromContentId(contentId)
          : getSignedUrlFromAttachmentId(attachmentId))
        if (mounted.current) {
          signedUrl ? setSignedUrl(signedUrl) : setIsResourceBroken(true)
        }
      } catch (error) {
        ninjaReportError(error)
      } finally {
        setIsLoading(false)
      }
    }
    !tempUri && getUrl()
  }, [contentId, attachmentId, mounted, tempUri, uploadStatus, setIsLoading])

  if (isLoading) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100px">
        <Spinner />
      </Box>
    )
  }

  if (isResourceBroken) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <StyledFontAwesomeIcon icon={faFileImage} color="ninjaRed" fontSize={6} />
        <StyledSpan fontSize={0} marginTop={2} fontWeight="bold" color="ninjaRed">
          {localized("Resource Not Found")}
        </StyledSpan>
      </Box>
    )
  }

  return (
    (tempUri || signedUrl) && (
      <StyledImage
        {...{
          src: tempUri || signedUrl,
          width: width ?? "auto",
          maxWidth: "100%",
          ...otherProps,
        }}
      />
    )
  )
})
