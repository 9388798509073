import { isProdEnvironment, ninjaHostNames, getEnvRoute } from "js/includes/common/utils/ssrAndWebUtils"

export const ninjaResourceCdn = "https://resources.ninjarmm.com"
const secureFormsCdn = "https://secureforms"

export const getSecuredFormsCdnRoute = environment => {
  const hostname = ninjaHostNames.find(i => window.location.hostname.endsWith(i))

  const route = getEnvRoute(environment)
  return `${secureFormsCdn}.${hostname}/${route}`
}

export const getNinjaResourcesCdnRoute = () => {
  const runtimeEnvironment = window.store.getState().application.environment
  const route = isProdEnvironment(runtimeEnvironment) ? `webapp/${runtimeEnvironment}` : "webapp-test"

  return `${ninjaResourceCdn}/${route}`
}
