import { useMemo, useState } from "react"
import styled from "@emotion/styled"

import RichTextEditor from "js/includes/components/RichTextEditor"
import { deserializeToSlate } from "js/includes/components/RichTextEditor/serializer"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

import { customElementRenderers, customSerializeToHtml } from "./utils"
import { Attachments } from "./Attachments"

const StyledEditorContainer = styled(Box)`
  ${({ theme, isPublicResponse }) => !isPublicResponse && `background-color: ${theme.colorAlertWarningWeakest};`}
`

export const WYSIWYGEditor = ({
  editor,
  editorData,
  onKeyDown,
  onChange,
  onBlur,
  hasError,
  addInlineImage,
  addAttachment,
  deleteAttachment,
  readOnly,
  uploadProgress,
  allowLinks,
  allowAttachments,
  allowInlineImages,
  customPortalRenderer,
  placeholder,
  isPublicResponse = true,
}) => {
  const attachments = useMemo(() => editorData?.uploads, [editorData])

  const [initialValue] = useState(
    editorData?.htmlBody
      ? deserializeToSlate({
          html: editorData.htmlBody,
          attachments,
          attachmentSerializerKey: "metadata.contentId",
        })
      : null,
  )

  return (
    <>
      <StyledEditorContainer width="100%" isPublicResponse={isPublicResponse}>
        <RichTextEditor
          {...{
            editor,
            readOnly,
            placeholder: placeholder || localized("Add description..."),
            initialValue,
            attachments,
            hasError,
            addAttachment,
            addInlineImage,
            onKeyDown,
            onChange,
            onBlur,
            customPortalRenderer,
            customElementRenderers,
            customSerializeToHtml,
            allowLinks,
            allowAttachments,
            allowInlineImages,
          }}
        />
      </StyledEditorContainer>
      <Attachments {...{ attachments, deleteAttachment, uploadProgress }} />
    </>
  )
}
